import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@worthy-npm/worthy-common-ui-components';
import {
    DisabledCallWrapper,
    DisabledWrapper,
    FloatingDivider,
    InsuranceText,
    ItemCountInShipment,
    ItemsCount,
    ItemsCountWrapper,
    Logos,
    MethodsCtaBtn,
    MethodWrapper,
    PartnersWrapper, ScheduleCallBtn,
    SchedulingMethodsContainer, SchedulingOptions,
    Title,
} from './SchedulingMethods.styles.tsx';
import { Icon } from 'src/components/Icons.tsx';
import { FormControl, RadioBtn } from 'src/styles/RadioBtn/RadioBtn.styles.tsx';
import {
    getMainItemPublicId,
    getSchedulingItems, getSelectedItems,
    getSelectedShippingMethod,
    nextStep,
    setShippingMethod,
} from 'src/store/schedulingSlice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { SchedulingType } from 'src/constants/item.constants.tsx';
import { formatDateTimeRange, getMainItemByValueCategory, isDatePassed, sendUserEvent } from 'src/helpers/common.ts';
import { Item } from 'src/types/item.ts';
import SchedulingHeader from 'src/components/SchedulingPopUp/schedulingHeader.tsx';
import Calendly from 'src/helpers/calendly.ts';
import { getUser } from 'src/store/userSlice';
import { getItemAppointment, getItems, isSomeItemLoading } from 'src/store/itemsSlice';
import { isItemPreScheduling } from 'src/helpers/scheduling/item.ts';
import { getRoute } from 'src/store/sharedSlice/shared.slice.ts';
import { Icon as CommonIcons } from '../Icons';
import ScheduleCallData from 'src/components/scheduleCall/scheduleCallData.tsx';
import { useExperimentVariant } from 'src/hooks/useExperiment.ts';
import ClickableHelpTooltip from 'src/components/ClickableHelpTooltip/ClickableHelpTooltip.tsx';
import Ga from 'src/services/ga.service/ga.ts';
import { sysNameScheduleToGa } from 'src/constants/commonConstants.tsx';
import { useLocation } from 'react-router-dom';
import { sdRoutes } from 'src/routes.tsx';

const Methods = [
    {
        value: SchedulingType.PICKUP,
        label: 'Schedule FedEx Pickup',
        btnTitle: 'Continue with Fedex Pickup',
        tooltipTxt: 'Select this option if you prefer FedEx to pick up your shipment directly from your specified location.',
        popular: true,
    },
    {
        value: SchedulingType.DROPOFF,
        label: 'Drop Off at FedEx',
        btnTitle: 'Continue with Fedex Drop-off',
        tooltipTxt: 'Choose this option to drop off your shipment at a designated FedEx location.',
        popular: false,
    },
];

const disabledText = {
    noSelectedItems: {
        title: 'Select Items for Shipment',
        text: 'There are no active items selected for shipping.',
    },
    noValidItems: {
        title: 'Why Can\'t I Ship My Item?',
        text: 'Shipping options are unavailable when your item(s) are in pending approval status.',
    },
};

const SchedulingMethods: React.FC<{ isPC: boolean, allPending: boolean }> = ({ isPC, allPending }) => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const user = useAppSelector(getUser);
    const route = useAppSelector(getRoute);
    const mainItemPublicId = useAppSelector(getMainItemPublicId);
    const somethingLoading = useAppSelector(isSomeItemLoading);
    const method: SchedulingType = useAppSelector(getSelectedShippingMethod);
    const itemList = useAppSelector(getItems);
    const items: Item[] = useAppSelector(getSchedulingItems);
    const selectedScheduled = useAppSelector(getSelectedItems);
    const popularExp = useExperimentVariant('most_popular');
    const shouldFire = location.pathname.includes(sdRoutes.shipping);

    const itemsCount = items.length;
    const [ selectedMethod, setSelectedMethod ] = useState<SchedulingType>(method);
    const [ btnTitle, setBtnTitle ] = useState(Methods.find(i => i.value === method)!.btnTitle);

    const [ callable, setCallable ] = useState<Item | undefined>(undefined);
    const [ scheduled, setScheduled ] = useState<string>('');
    const [ newCallableItem, setNewCallableItem ] = useState<Item>();
    const appointment = useAppSelector((state) => getItemAppointment(state, callable?.publicId));

    const [ shouldHidePartnersWrapper, setShouldHidePartnersWrapper ] = useState(allPending && route.includes('shipping') || !selectedScheduled.length);

    useEffect(() => {
        setShouldHidePartnersWrapper(allPending && route.includes('shipping') || !selectedScheduled.length);
    }, [ allPending, selectedScheduled ]);

    useEffect(() => {
        !isPC && shouldFire && Ga.SendActionOpen({
            actionedObject2: 'schedulingFunnel',
            actionedValue2: 'shipmentMethodSelectionStepView',
            location1: 'sellerDashboard',
            location2: 'shippingScreen',
            location3: 'shippingOptionsComponent',
            location4: 'shippingMethodSelection',
        });
        window.addEventListener('message', handleCalendly);
    }, []);

    useEffect(() => {
        const schedulingItems = itemList.filter((item) => isItemPreScheduling(item.status));
        const watch = schedulingItems.find((item) => item.itemType?.toLowerCase().includes('watch'));

        let callableItem = schedulingItems.find(item => item.appointmentDetails);

        if (!callableItem) {
            callableItem = watch || getMainItemByValueCategory(schedulingItems);
        }

        setCallable(callableItem);
    }, [ itemList ]);

    useEffect(() => {
        const schedulingItems = itemList.filter((item) => isItemPreScheduling(item.status));
        const watch = schedulingItems.find((item) => item.itemType?.toLowerCase().includes('watch'));

        if (appointment && !isDatePassed(new Date(appointment.endTime))) {
            const higherCategoryItem = watch ? watch : getMainItemByValueCategory(schedulingItems);
            if (higherCategoryItem.publicId !== callable?.publicId) {
                setNewCallableItem(higherCategoryItem);
            }

            const date = formatDateTimeRange(appointment.startTime, appointment.endTime);
            setScheduled(date);
        } else {
            setScheduled('');
        }

    }, [ appointment, callable ]);

    const handleCalendly = (event: MessageEvent) => {
        if (Calendly.isCalendlyEvent(event)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            if (event.data.event.indexOf('event_scheduled') > -1) {
                // todo separate event shipping from call schedule
                // Ga.SendActionSuccess({
                //     actionedObject1: 'shippingOptionsScheduled',
                //     actionedValue1: 'callScheduled',
                //     location1: 'sellerDashboard',
                //     location2: 'shippingScreen',
                //     location3: 'shippingOptionsComponent',
                // });
                console.info('Calendly event_scheduled', event.data);
            }
        }
    };

    const scheduleCall = () => {
        // valid and pending items
        const schedulingItems = itemList.filter((item) => isItemPreScheduling(item.status));

        const watch = schedulingItems.find((item) => item.itemType?.toLowerCase().includes('watch'));
        const callableItem = watch ? watch : getMainItemByValueCategory(schedulingItems);
        const link = Calendly.getCalendlyLinkByValueCategory(callableItem, user);

        Calendly.showPopupWidget(link, Calendly.calendlyParams(callableItem, user));
    };

    const onNextClick = () => {
        dispatch(setShippingMethod(selectedMethod));
        dispatch(nextStep());
        shouldFire && Ga.SendActionNavigate({
            actionedObject2: 'schedulingFunnel',
            actionedValue2: 'shipmentMethodSelectionStepNext',
            actionedObject3: 'schedulingShipmentType',
            actionedValue3: sysNameScheduleToGa[selectedMethod],
            location1: 'sellerDashboard',
            location2: 'shippingScreen',
            location3: 'shippingOptionsComponent',
            location4: 'shippingMethodSelection',
        });
    };

    const getRadioBtn = (value: string, label: string, tooltipTxt: string, popular: boolean) => (
        <MethodWrapper
            key={ value }
            banner={ popular }
            experiment={ !!popularExp }
            isSelected={ selectedMethod.includes(value) }
        >
            <FormControl
                key={ `form-control-${ value }` }
                data-automation={ `radio-${ value }` }
                value={ value }
                control={ <RadioBtn /> }
                label={ label }
                disabled={ !mainItemPublicId || shouldHidePartnersWrapper || somethingLoading }
            />
            {
                !popularExp && (
                    <ClickableHelpTooltip
                        key={ `tooltip-${ value }` }
                        title={ tooltipTxt }
                        large={ true }
                    />
                )
            }

        </MethodWrapper>
    );

    const getPartnersLogos = () => (
        <Logos>
            <Icon.FedexIcon
                color="secondary"
                fontSize="large"
                sx={ {
                    width: '65px',
                    height: '20px',
                } }
            />
            <Icon.LloydsIcon
                color="secondary"
                fontSize="large"
                sx={ {
                    width: '63px',
                    height: '13px',
                } }
            />
            <Icon.GiaIcon
                color="secondary"
                fontSize="large"
                sx={ {
                    width: '65px',
                    height: '29px',
                } }
            />
        </Logos>
    );

    const getItemsCount = () => (
        <ItemsCountWrapper>
            <ItemsCount>
                { itemsCount } { itemsCount === 1 ? 'item' : 'items' }
            </ItemsCount>
        </ItemsCountWrapper>
    );

    const handleMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value as SchedulingType;
        sendUserEvent(`${ value } radio button selected`, mainItemPublicId);
        setSelectedMethod(value);
        setBtnTitle(Methods.find((method) => method.value === value)!.btnTitle);
    };

    const getSchedulingMethods = () => {
        const shipMethods = popularExp === 2 ? Methods.slice().reverse() : Methods;
        return (
            <SchedulingOptions
                banner={ !!popularExp }
                aria-labelledby="scheduling-popup-radio-buttons-group-label"
                value={ selectedMethod }
                name="scheduling-popup-radio-buttons-group"
                sx={ {
                    alignSelf: 'stretch',
                } }
                onChange={ (e) => handleMethodChange(e) }
            >
                { shipMethods.map((method) =>
                    getRadioBtn(method.value, method.label, method.tooltipTxt, method.popular)) }
            </SchedulingOptions>
        );
    };

    const getDisabledText = () => {
        return allPending && route.includes('shipping') ? disabledText.noValidItems.text : disabledText.noSelectedItems.text;
    };

    const getDisabledTitle = () => {
        return allPending && route.includes('shipping') ? disabledText.noValidItems.title : disabledText.noSelectedItems.title;
    };

    return (
        <>
            <SchedulingHeader isPC={ isPC } title="Shipping options" firstStep={ true } />
            <Title align="left" disabled={ !mainItemPublicId || shouldHidePartnersWrapper || somethingLoading }>
                How would you like to send your item?
            </Title>

            <SchedulingMethodsContainer empty={ shouldHidePartnersWrapper }>
                { getSchedulingMethods() }

                { !shouldHidePartnersWrapper && (
                    <>
                        <PartnersWrapper>
                            <FloatingDivider flexItem />
                            <InsuranceText>**Insurance and fees covered</InsuranceText>
                            { getPartnersLogos() }
                        </PartnersWrapper>
                        <ItemCountInShipment>
                            <InsuranceText>Items in this shipment</InsuranceText>
                            { getItemsCount() }
                        </ItemCountInShipment>
                    </>
                ) }

                <MethodsCtaBtn
                    variant="contained"
                    onClick={ onNextClick }
                    disableElevation
                    disabled={ !mainItemPublicId || shouldHidePartnersWrapper || somethingLoading }
                    data-automation="shipping-method-btn"
                >
                    { btnTitle }
                </MethodsCtaBtn>

                {
                    shouldHidePartnersWrapper && !scheduled && (
                        <DisabledWrapper>
                            <Box>
                                <Typography variant="caption" fontWeight="600">
                                    { getDisabledTitle() }
                                </Typography>
                                <Typography>
                                    { getDisabledText() }
                                </Typography>
                            </Box>
                            <ScheduleCallBtn
                                disableElevation
                                variant="contained"
                                onClick={ scheduleCall }
                                data-automation="shipping-method-btn"
                            >
                                <CommonIcons.PhoneIcon sx={ { width: '18px', height: '18px', marginRight: '8px' } } />
                                Schedule Call
                            </ScheduleCallBtn>
                        </DisabledWrapper>
                    )
                }
                {
                    shouldHidePartnersWrapper && scheduled && (
                        <DisabledCallWrapper>
                            <Typography variant="caption" fontWeight="600">
                                { getDisabledTitle() }
                            </Typography>
                            <Typography
                                marginBottom="16px"
                            >
                                { getDisabledText() }
                            </Typography>
                            <ScheduleCallData item={ callable } darkMode={ true } superItem={ newCallableItem } />
                        </DisabledCallWrapper>
                    )
                }
            </SchedulingMethodsContainer>
        </>
    );
};

export default SchedulingMethods;
